import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
} from "react-icons/fa";
import pay from "../../Assets/part.png";
import logo from "../../Assets/last.png";
import { FaX } from "react-icons/fa6";
import Foot from "../tripsccreens/Foot";

const FooterLinks1 = [
  {
    title: "Airport Transportation",
    link: "/#",
  },
  {
    title: "Hourly Charter Service",
    link: "/#about",
  },
  {
    title: "Wedding Transportation",
    link: "/#about",
  },
  {
    title: "Corporate & Executive Transportation",
    link: "/#about",
  },
  {
    title: "Special Day Transportation",
    link: "/#about",
  },
];

const Oku = [
  {
    "value": "Atlanta Limo Services",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Service in Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Car Services",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Wedding Limousine Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Bus Rental Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Georgia Wedding Limousines",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Wedding Limo Rental",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Service Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Airport Limo Service Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Airport Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Rentals Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Car Service Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Airport Service Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Airport Car Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Wedding Guest Transportation",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Wedding Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Car Service Atlanta Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Hummer Limo",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Wedding Limousine",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Stretch Limos",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Party Bus Limos",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Hummer Limo Rental Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Atlanta Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo To Hartsfield Jackson Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Airport Limo Atlanta",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Bachelorette Party Limo",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limousine Rentals",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Transportation From Atlanta Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Car Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Airport Transportation",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta, GA Limousine Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Limousine Specials",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limo Service in GA",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limousine Rentals",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Hummer Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta SUV Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Limousine Transportation",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "ATL Limo Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "ATL Limousines",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta ATL Car Service",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Atlanta Limo Discount",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Party Limo",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Limousine Rentals Near Me",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Best Limo",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Hartsfield-Jackson Atlanta International Airport Limo Services",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Transportation to Atlanta Airport",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Transportation Services Atlanta GA",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Airport Transportation in Atlanta GA",
    "link": "https://blessedlimos.com/services"
  },
  {
    "value": "Serving Atlanta & Surrounding Cities",
    "link": "https://blessedlimos.com/services"
  }
]


const FooterLinks2 = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Contact",
    link: "/#contact",
  },
  {
    title: "Book A Ride",
    link: "/#contact",
  },
  {
    title: "Our Fleets",
    link: "/#contact",
  },
  {
    title: "Privacy Policy",
    link: "/#contact",
  },
  {
    title: "Terms & Conditions",
    link: "/#contact",
  },
  {
    title: "FAQ",
    link: "/#contact",
  },
];

const Footer = () => {
  return (
    <div className="bg-[#f4f4f4] dark:bg-dark mt-14 rounded-t-3xl">

      {/* <Foot /> */}
      <img src="https://www.limoserviceatlanta.com/wp-content/uploads/2017/05/banner-3.jpg?1723238677546" className="w-[100%]"/>


      <section className="lg:ml-32 mx-auto px-4 py-5 sm:py-8">
        <div className="flex flex-col md:flex-row justify-between items-start">
          {/* Company Details */}
          <div className="flex flex-col mb-6 md:mb-0 lg:w-[20%]">
            <h1 className="text-xl sm:text-3xl font-bold mb-3 text-left">
            {/* LAST MINUTE DISPATCH */}

            <img src={logo} alt="" className='w-20 h-20 lg:w-20 lg:h-20' />

            </h1>
            <p className="text-sm">
              Your Luxury Comfort is our Optimist Priority. Book a ride now and
              testify...
            </p>
            <div className="flex items-center mt-3">
              <FaLocationArrow />
              <p className="ml-2">
              1249 Jennie Ln SW  
Lilburn, Atlanta GA 30047</p>
            </div>
            <div className="flex items-center mt-2">
              <FaMobileAlt />
              <p className="ml-2">800-556-1849</p>
            </div>
            {/* Social Handles */}
            <div className="flex items-center mt-4">
              <a href="https://www.instagram.com/blessedlimoglobal?igsh=N3phdmtqOHR5anRv" className="mr-3">
                <FaInstagram className="text-3xl hover:text-primary duration-300" />
              </a>
              <a href="https://www.facebook.com/share/hbXreSwVRDz2vhT2/?mibextid=LQQJ4d" className="mr-3">
                <FaFacebook className="text-3xl hover:text-primary duration-300" />
              </a>
              <a href="https://x.com/blessedlimo206?s=21" className="mr-3">
                <FaX className="text-3xl hover:text-primary duration-300" />
              </a>
              <a href="https://www.linkedin.com/in/blessed-limo-83187a235">
                <FaLinkedin className="text-3xl hover:text-primary duration-300" />
              </a>
            </div>
          </div>

          {/* Payment Image */}
          <div className="grid items-center justify-center lg:-ml-40 mb-6 md:mb-0">
            <img src={pay} alt="Payment Methods" className="w-96 h-auto" />
            <div class="text-center lg:ml-10 sm:text-left">
                            <p class="text-xs">Subscribe to get emails from LAST MINUTE DISPATCH</p>

                            <input className="p-2 my-5 px-4 bg-[#eee] rounded-2xl" placeholder="Email..."  type="email"/>
                            <button onClick={() => alert('Successful')} className="p-3 px-5 m-2 text-xs bg-white text-primary font-bold rounded-2xl">
                                Subscribe
                            </button>
                        </div>
          </div>

          {/* Links Section */}
          <div className="flex flex-wrap px-10 lg:px-40  justify-between w-full md:w-auto">
            {/* Link Section 1 */}
            <div className="flex flex-col w-1/2 md:w-60 md:-ml-72 mb-6 md:mb-0">
              <h1 className="text-xl sm:text-xl font-bold mb-3 text-left">
                Our Sevices
              </h1>
              <ul className="flex flex-col gap-2">
                {FooterLinks1.map((link, index) => (
                  <li
                    key={index}
                    className="cursor-pointer hover:text-primary duration-300 flex items-center"
                  >
                    {/* <span className="mr-1">&#11162;</span> */}
                    <a href={link.link}>+ {link.title}</a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Link Section 2 */}
            <div className="flex flex-col w-1/2 md:w-auto">
              <h1 className="text-xl sm:text-xl font-bold mb-3 text-left">
                Additional Links
              </h1>
              <ul className="flex flex-col gap-2">
                {FooterLinks2.map((link, index) => (
                  <li
                    key={index}
                    className="cursor-pointer hover:text-primary duration-300 flex items-center"
                  >
                    {/* <span className="mr-1">&#11162;</span> */}
                    <a href={link.link}>+ {link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* SEO */}
      <div className="items-center px-10 lg:px-40 pb-20 text-center">
      {Oku.map((link, index) => (
                    <a className="text-sm" href={link.link}>{link.value} &nbsp; | &nbsp; </a> 
                ))}

                  <div style={{borderColor: 'gray'}} class="mt-5 border-t pt-6">
                    <div class="text-center sm:flex sm:justify-between sm:text-left">
                        <p class="text-sm">
                            <span class="block sm:inline">All rights reserved </span>

                            {/* <a
                                class="inline-block text-gray-800 underline transition hover:text-teal-600/75"
                                href="/"
                            > */}
                            {/* </a> */}

                            {/* <span>&middot;</span> */}

                            {/* <a
                                class="inline-block text-gray-300 underline transition hover:text-teal-600/75"
                                href="/"
                            > */}
                            {/* Terms & Conditions  
                                 Privacy Policy  */}
                            {/* </a> */}
                        </p>

                        <p class="mt-4 text-sm sm:order-first sm:mt-0">
                            &copy; {(new Date().getFullYear())} LAST MINUTE DISPATCH
                        </p>
                    </div>
                </div>
                          </div>
    </div>
  );
};

export default Footer;
